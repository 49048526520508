html, * {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body, * {
  font-family: 'SF Pro Text',-apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
}

body, p {
  margin: 0;
  padding: 0;
}

html, body, #root, .app {
  height: 100%;
}

.app {
  overflow: hidden scroll;
}

.theme-dark {
  background: #000000;
  color: #FFFFFF;
}

.theme-light {
  background: #FFFFFF;
  color: #000000;
}

.wrapper {
  margin: 0 auto;
  max-width: 420px;
  padding: 0 16px;
}

.title {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 0.136px;
  margin: 16px 0;
}

.update_date {
  font-size: 13px;
  font-weight: 400;
  color: #BF5AF2;
  letter-spacing: -0.01px;
}

.theme-light .info-field {
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.theme-dark .info-field {
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.info-field {
  padding: 32px 0;
}

.info-field:last-child {
  border-bottom: none;
}

.info-field__img {
  width: 100%;
  margin-bottom: 12px;
}

.info-field__title {
  margin: 0 0 12px;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -0.057px;
  line-height: 28px;
}

.info-field__description {
  font-size: 16px;
  font-weight: 500;
  color: #8A8A8E;
  letter-spacing: -0.05px;
  line-height: 21px;
}

